<template>
    <div id="islemler">

        <div >
            <b-alert variant="success" show><strong>{{tweetIzniVerenSayisi}}</strong> Kişi Tweet Atma İzni Verdi.</b-alert>
            <b-alert variant="success" show><strong>{{retweenIzniVerenSayisi}}</strong> Kişi Retweet İzni Verdi.</b-alert>
            <b-alert variant="info" show><strong>{{likeIzniVerenSayisi}}</strong> Kişi Like İzni Verdi.</b-alert>

            <b-alert variant="danger" show>
                3 Saatte toplam retweet + tweet attırma + tweet cevaplama = maximum <strong>300</strong> tane. <br>
                24 Saatte toplam like maximum <strong>1000</strong> tane.
            </b-alert>

        </div>

        <div class="flex-container">
            <div class="item">
                <b-button class="item-button"
                        @click="$router.push({name: 'RetweetLike'})"
                          variant="success">
                    Retweet/Like İşlemi <br>
                </b-button>
            </div>
            <div class="item">
                <b-button class="item-button"
                        @click="$router.push({name: 'Sablonlar'})"
                          variant="primary">
                    Tweet Attırma İşlemleri <br>
                </b-button>
            </div>

            <div class="item">
                <b-button class="item-button"
                          @click="$router.push({name: 'Gonulluler'})"
                          variant="info">
                    Gönüllüler <br>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { RetweetIziniSayisi } from "@/api/hesap"
    import {LikeIziniSayisi} from "@/api/hesap"
    import {TweetAtmaIziniVerenSayisi} from "@/api/hesap"

    export default {
        name: "",
        data() {
            return {
                retweenIzniVerenSayisi: 0,
                likeIzniVerenSayisi: 0,
                tweetIzniVerenSayisi: 0,
            }
        },
        async created() {
            this.retweenIzniVerenSayisi = await RetweetIziniSayisi()
            this.likeIzniVerenSayisi = await LikeIziniSayisi()
            this.tweetIzniVerenSayisi = await TweetAtmaIziniVerenSayisi()
        },
    }
</script>

<style scoped lang="scss">
    .flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eeeeee;
        flex-wrap: wrap;
        padding: 15px;

        .item {
            margin: 10px;

            .item-button {
                padding: 20px;
                border-radius: 10px;
            }

        }

    }
</style>
